import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import "../styles/billing.css"; // Import the CSS file;
import UpdatePaymentMethod from "../components/update-payment-method";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { set } from "firebase/database";
import PhoneInput from "react-phone-input-2";

function SettingsPage() {
  // const [walletBalance, setWalletBalance] = useState(0);
  // const [rechargeAmount, setRechargeAmount] = useState(10);
  // const [currentCard, setCurrentCard] = useState(null);

  const [currentForwardingPhoneNumber, setCurrentForwardingPhoneNumber] =
    useState("");

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentData = agentDoc.data();
      setCurrentForwardingPhoneNumber(agentData.phone_number);
    };

    fetchData();
  }, []);

  const [newForwardingPhoneNumber, setNewForwardingPhoneNumber] = useState("");

  const handleForwardingPhoneNumberChange = (event) => {
    setNewForwardingPhoneNumber(event.target.value);
  };

  const handleUpdateForwardingPhoneNumber = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];
    const agentDocRef = doc(db, "agents", agentDoc.id);

    await updateDoc(agentDocRef, {
      phone_number: `+${newForwardingPhoneNumber}`,
    });

    window.location.reload();
  };

  return (
    <>
      <div style={{ height: "75px" }} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={4}
      >
        <Box
          width="48%"
          p={3}
          ml={3}
          mr={3}
          style={{
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            borderRadius: "8px",
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
            color: "white",
          }}
          justifyItems="center"
          alignContent="center"
          textAlign="center"
        >
          <Typography variant="h5" gutterBottom>
            <b>Current forwarding phone number</b>
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {currentForwardingPhoneNumber}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={4}
      >
        <Box
          width="48%"
          p={3}
          mr={3}
          ml={3}
          style={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            color: "black",
          }}
          textAlign="center"
        >
          <Typography variant="h6" gutterBottom>
            <b>New forwarding phone number</b>
          </Typography>
          <Grid item xs={12} sm={9}>
            <Box width={"100%"}>
              <br />
              <PhoneInput
                country={"us"}
                value={currentForwardingPhoneNumber}
                disableDropdown={true}
                disableCountryGuess={true}
                onChange={setNewForwardingPhoneNumber}
                enableLongNumbers={true}
                inputStyle={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  height: "56px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                }}
                required
              />
            </Box>
          </Grid>
          {/* <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="New forwarding phone number"
            value={newForwardingPhoneNumber}
            onChange={handleForwardingPhoneNumberChange}
            inputProps={{
              pattern: "^\\+?[1-9]\\d{0,14}$",
              title: "Please enter a valid phone number",
            }}
            InputProps={{
              inputComponent: "input",
            }}
          /> */}
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateForwardingPhoneNumber}
          >
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default SettingsPage;
