import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { auth, db, functions } from "../config/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import "../styles/test-phone-calls.css"; // import the CSS file
import { Link } from "react-router-dom";

import { httpsCallable } from "firebase/functions";
import { Pause, PlayArrowOutlined } from "@mui/icons-material";

function Home() {
  const [dashboardData, setDashboardData] = useState({
    totalLeads: 0,
    totalLiveTransfers: 0,
    totalPickUps: 0,
    totalPickUpsFromEvenDials: 0,
    totalDials: 0,
    totalFailedNumbers: 0,
    totalVoiceMails: 0,
    totalBusyAndNoAnswer: 0,
    totalLeadCallBacks: 0,
    totalLocalLeadsLiveTransfers: 0,
    liveTransferToPickupRate: 0,
    totalOverallDials: 0,
    totalPickUpsLocal: 0,
    totalNoAnswer: 0,
    walletBalance: 0,
    freeMinutes: 0,
    queueActive: false,
    leadsDialedMoreThanOnce: 0,
    callingQueuePaused: false,
  });
  const [firstName, setFirstName] = useState("");
  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [resumingCallsDialogOpen, setResumingCallsDialogOpen] = useState(false);
  const [resumingCallsForQueueDialogOpen, setResumingCallsForQueueDialogOpen] =
    useState(false);
  const [pausingCallsForQueueDialogOpen, setPausingCallsForQueueDialogOpen] =
    useState(false);
  // const [pausingCallsDialogOpen, setPausingCallsDialogOpen] = useState(false);
  const [queueName, setQueueName] = useState("");

  const [selectedQueue, setSelectedQueue] = useState("med_supp_cc_lt");

  const handleQueueChange = (event) => {
    setSelectedQueue(event.target.value);
  };

  useEffect(() => {
    const initializeDashboardData = () => ({
      totalLeads: 0,
      totalLiveTransfers: 0,
      totalPickUps: 0,
      totalPickUpsFromEvenDials: 0,
      totalLiveTransfersFromEvenDials: 0,
      totalTranscribedCalls: 0,
      totalDials: 0,
      totalFailedNumbers: 0,
      totalVoiceMails: 0,
      totalBusyAndNoAnswer: 0,
      totalLocalLeadsLiveTransfers: 0,
      liveTransferToPickupRate: 0,
      totalOverallDials: 0,
      totalPickUpsLocal: 0,
      totalNoAnswer: 0,
      walletBalance: 0,
      queueActive: false,
      leadsDialedMoreThanOnce: 0,
      callingQueuePaused: false,
    });

    setDashboardData(initializeDashboardData());

    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentId = agentDoc.id;

      // Fetch and set the first name
      const agentData = agentDoc.data();
      setFirstName(agentData.first_name);

      // Function to check if all queues are paused
      const areAllQueuesPaused = (queueStatus) => {
        return Object.values(queueStatus).every((queue) => queue.paused);
      };

      // Listen for changes in the agent's document
      const agentDocRef = doc(db, "agents", agentId);
      const unsubscribeAgent = onSnapshot(agentDocRef, (doc) => {
        const data = doc.data();
        const allQueuesPaused = areAllQueuesPaused(data.queue_status);
        setDashboardData((prevState) => ({
          ...prevState,
          callingQueuePaused: allQueuesPaused,
          freeMinutes: data.free_minutes,
          walletBalance: data.wallet_balance,
        }));
        setAgentData({ id: doc.id, ...data });
        setLoading(false);
      });

      // Listen for changes in the voicemail_trigger subcollection
      // const voicemailTriggerRef = collection(
      //   db,
      //   "agents",
      //   agentId,
      //   "voicemail_trigger"
      // );
      // const unsubscribeVoicemailTrigger = onSnapshot(
      //   voicemailTriggerRef,
      //   (snapshot) => {
      //     const totalVoicemail = snapshot.docs.filter(
      //       (doc) => doc.data().call_type === selectedQueue
      //     ).length;
      //     setDashboardData((prevState) => ({
      //       ...prevState,
      //       totalVoiceMails: totalVoicemail,
      //     }));
      //   }
      // );

      // Listen for changes in the lead_call_backs subcollection
      const leadCallBacksRef = collection(
        db,
        "agents",
        agentId,
        "lead_call_backs"
      );
      const unsubscribeLeadCallBacks = onSnapshot(
        leadCallBacksRef,
        async (snapshot) => {
          const leadDocPromises = snapshot.docs.map((_doc) => {
            const leadId = _doc.data()["lead_id"];
            const leadDocRef = doc(db, "agents", agentId, "leads", leadId);
            return getDoc(leadDocRef);
          });

          const leadDocs = await Promise.all(leadDocPromises);
          const totalLeadCallBacks = leadDocs.filter(
            (leadDoc) =>
              leadDoc.exists() && leadDoc.data()["lead_type"] === selectedQueue
          ).length;

          setDashboardData((prevState) => ({
            ...prevState,
            totalLeadCallBacks,
          }));
        }
      );

      // Listen for changes in the leads subcollection
      const leadsRef = collection(db, "agents", agentId, "leads");
      const filteredLeadsQuery = query(
        leadsRef,
        where("lead_type", "==", selectedQueue)
      );

      const unsubscribeLeads = onSnapshot(
        filteredLeadsQuery,
        async (snapshot) => {
          const totalLeads = snapshot.size;
          let totalPickUps = 0;
          let totalPickUpsFromEvenDials = 0;
          let totalPickUpsLocal = 0;
          let totalFailed = 0;
          let totalLeadsDialed = 0;
          let totalNoAnswer = 0;
          let totalDialsFiltered = 0;
          let leadsDialedMoreThanOnce = 0;
          const localLeadsStates = [];

          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            const {
              full_state,
              call_status,
              call_attempts,
              failed_number,
              phone_number,
            } = data;

            if (
              ["Florida", "Indiana", "Michigan", "Ohio", "Texas"].includes(
                full_state
              )
            ) {
              localLeadsStates.push(phone_number);
            }

            if (call_status === "picked_up") {
              totalPickUps++;
              // if (call_attempts % 2 === 0) {
              //   totalPickUpsFromEvenDials++;
              // }
              // if (
              //   ["Florida", "Indiana", "Michigan", "Ohio", "Texas"].includes(
              //     full_state
              //   )
              // ) {
              //   totalPickUpsLocal++;
              // }
            }

            if (failed_number) {
              // totalFailed++;
            } else {
              totalDialsFiltered += call_attempts || 0;
              if (call_status !== "queued") {
                totalLeadsDialed++;
              }
            }

            if (["no_answer", "busy"].includes(call_status)) {
              totalNoAnswer++;
            }

            // if (call_attempts > 1) {
            //   leadsDialedMoreThanOnce++;
            // }
          });

          setDashboardData((prevState) => ({
            ...prevState,
            totalLeads,
            totalPickUps,
            totalPickUpsFromEvenDials,
            totalDials: totalLeadsDialed,
            totalOverallDials: totalDialsFiltered,
            leadsDialedMoreThanOnce,
            totalFailedNumbers: totalFailed,
            totalNoAnswer,
            totalPickUpsLocal,
          }));
        }
      );

      // Listen for changes in the live_transfers subcollection
      const liveTransfersRef = collection(
        db,
        "agents",
        agentId,
        "live_transfers"
      );
      const filteredLiveTransfersQuery = query(
        liveTransfersRef,
        where("call_type", "==", selectedQueue)
      );

      const unsubscribeLiveTransfers = onSnapshot(
        filteredLiveTransfersQuery,
        async (snapshot) => {
          const totalLiveTransfers = snapshot.size;
          // const leadsRef = collection(db, "agents", agentId, "leads");
          // let _totalLocalLeadsLiveTransfers = 0;
          // let _totalLiveTransfersFromEvenDials = 0;

          // const leadDocPromises = snapshot.docs.map((doc) => {
          //   const leadPhoneNumber = doc.data()["lead_phone_number"];
          //   const leadDocRef = query(
          //     leadsRef,
          //     where("phone_number", "==", leadPhoneNumber)
          //   );
          //   return getDocs(leadDocRef);
          // });

          // const leadDocs = await Promise.all(leadDocPromises);

          // leadDocs.forEach((leadDoc) => {
          //   if (!leadDoc.empty) {
          //     if (leadDoc.docs[0].data()["call_attempts"] % 2 === 0) {
          //       _totalLiveTransfersFromEvenDials++;
          //     }
          //   }
          // });

          // snapshot.docs.forEach((doc) => {
          //   if (localLeadsStates.includes(doc.data()["lead_phone_number"])) {
          //     _totalLocalLeadsLiveTransfers++;
          //   }
          // });

          setDashboardData((prevState) => ({
            ...prevState,
            totalLiveTransfers,
            // totalLiveTransfersFromEvenDials: _totalLiveTransfersFromEvenDials,
            // totalLocalLeadsLiveTransfers: _totalLocalLeadsLiveTransfers,
            // liveTransferToPickupRate:
            //   (totalLiveTransfers / prevState.totalPickUps) * 100,
          }));
        }
      );

      // Cleanup function to unsubscribe from snapshots
      return () => {
        unsubscribeAgent();
        // unsubscribeVoicemailTrigger();
        unsubscribeLeadCallBacks();
        unsubscribeLeads();
        unsubscribeLiveTransfers();
      };
    };

    fetchData();
  }, [selectedQueue]);

  // const showResumingModal = () => {
  //   setResumingCallsDialogOpen(true);
  // };

  const showResumingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setResumingCallsForQueueDialogOpen(true);
  };

  // const showPausingModal = () => {
  //   setPausingCallsDialogOpen(true);
  // };

  const showPausingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setPausingCallsForQueueDialogOpen(true);
  };

  // const hideResumingModal = () => {
  //   setResumingCallsDialogOpen(false);
  // };

  const hideResumingQueueModal = () => {
    setQueueName("");
    setResumingCallsForQueueDialogOpen(false);
  };

  // const hidePausingModal = () => {
  //   setPausingCallsDialogOpen(false);
  // };

  const hidePausingQueueModal = () => {
    setQueueName("");
    setPausingCallsForQueueDialogOpen(false);
  };

  const resumeCallsForAllQueues = async () => {
    // Code to call the cloud function
    const resumeCallsForAllQueues = httpsCallable(
      functions,
      "resumeCallsForAllQueues"
    );

    return await resumeCallsForAllQueues().then((result) => {
      if (result.data == null) {
        return;
      } else {
        console.log(result.data);
        return;
      }
    });
  };

  const resumeCallsForQueue = async (queue) => {
    // Code to call the cloud function
    const resumeCallsForQueue = httpsCallable(functions, "resumeCallsForQueue");

    return await resumeCallsForQueue({
      queue: queue,
    }).then((result) => {
      if (result.data == null) {
        return;
      } else {
        console.log(result.data);
        return;
      }
    });
  };

  const pauseCallsForQueueCF = async (queue) => {
    // Code to call the cloud function
    const pauseCallsForQueue = httpsCallable(functions, "pauseCallsForQueue");

    return await pauseCallsForQueue({
      queue: queue,
    }).then((result) => {
      if (result.data == null) {
        return;
      } else {
        console.log(result.data);
        return;
      }
    });
  };

  // const pauseCallsForAllQueues = async () => {
  //   // Code to call the cloud function
  //   return new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate async call
  // };

  const pauseCallsForQueue = async (queue) => {
    await pauseCallsForQueueCF(queue);
  };

  const handlePauseResumeQueue = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];
    const agentId = agentDoc.id;

    const agentDocRef = doc(db, "agents", agentId);

    // if (dashboardData.callingQueuePaused) {
    //   const updatedQueueStatus = {};
    //   for (let i = 0; i < queues.length; i++) {
    //     updatedQueueStatus[queues[i]] = { paused: false };
    //   }
    //   await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
    //   setDashboardData((prevData) => ({
    //     ...prevData,
    //     callingQueuePaused: false,
    //   }));
    //   setAgentData((prevData) => ({
    //     ...prevData,
    //     queue_status: updatedQueueStatus,
    //   }));

    //   showResumingModal();

    //   try {
    //     // Call the cloud function
    //     await resumeCallsForAllQueues();
    //   } catch (error) {
    //     console.error("Error resuming calls for all queues:", error);
    //   } finally {
    //     // Hide the modal
    //     hideResumingModal();
    //   }
    // } else {
    //   const updatedQueueStatus = {};
    //   for (let i = 0; i < queues.length; i++) {
    //     updatedQueueStatus[queues[i]] = { paused: true };
    //   }
    //   await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
    //   setDashboardData((prevData) => ({
    //     ...prevData,
    //     callingQueuePaused: true,
    //   }));
    //   setAgentData((prevData) => ({
    //     ...prevData,
    //     queue_status: updatedQueueStatus,
    //   }));

    //   showPausingModal();

    //   try {
    //     // Call the cloud function
    //     await pauseCallsForAllQueues();
    //   } catch (error) {
    //     console.error("Error resuming calls for all queues:", error);
    //   } finally {
    //     // Hide the modal
    //     hidePausingModal();
    //   }
    // }
  };

  const getActiveQueues = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);
    const agentDoc = agentSnapshot.docs[0];

    if (!agentDoc.exists()) {
      console.error("No such document!");
      return [];
    }

    const queueStatus = agentDoc.data().queue_status;
    const activeQueues = Object.keys(queueStatus).filter(
      (queue) => !queueStatus[queue].paused
    );

    return activeQueues;
  };

  const handleToggle = async (queue) => {
    if (!agentData) return;

    const newStatus = !agentData.queue_status[queue].paused;
    const updatedQueueStatus = {
      ...agentData.queue_status,
      [queue]: { paused: newStatus },
    };
    const agentDocRef = doc(db, "agents", agentData.id);

    // showPausingQueueModal(queue);

    // await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
    // await pauseCallsForQueue(queue);

    // hidePausingQueueModal();

    if (newStatus == true) {
      showPausingQueueModal(queue);

      await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
      await pauseCallsForQueue(queue);

      hidePausingQueueModal();
    } else {
      showResumingQueueModal(queue);

      await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
      await resumeCallsForQueue(queue);

      hideResumingQueueModal();
    }

    setAgentData((prevData) => ({
      ...prevData,
      queue_status: updatedQueueStatus,
    }));
  };

  const queueMap = {
    med_supp_cc_lt: "Medicare Artificial Voice Leads",
    med_supp_t65_fb_lt: "Medicare T65 Facebook",
    med_supp_fb_lt: "Medicare Supplement Facebook",
    mapd_fb_lt: "MAPD Facebook",
    final_expense_fb_lt: "Final Expense Facebook",
    final_expense_cc_lt: "Final Expense Artificial Voice Leads",
    aca_cc_lt: "ACA Artificial Voice Leads",
  };

  const queues = Object.keys(queueMap);

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ padding: "2rem" }}>
      <center>
        <Typography variant="h4" component="h1" gutterBottom>
          <strong>Welcome back, {firstName}</strong>
        </Typography>
        <div>
          <label htmlFor="queueSelect">Select Queue: </label>
          <select
            id="queueSelect"
            value={selectedQueue}
            onChange={handleQueueChange}
          >
            <option value="">--Select a Queue--</option>
            {queues.map((queue) => (
              <option key={queue} value={queue}>
                {queueMap[queue]}
              </option>
            ))}
          </select>

          {/* Your existing code */}
          {selectedQueue && (
            <div>
              {/* Query and display statistics based on selectedQueue */}
              <p>Selected Queue: {queueMap[selectedQueue]}</p>
              {/* Add your logic to query and display statistics here */}
            </div>
          )}
        </div>
      </center>
      <Grid
        container
        spacing={3}
        style={{ marginTop: "2rem", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total leads imported</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalLeads)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Leads dialed</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalDials)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total pickups</b>
              </Typography>
              <Typography variant="h4">{dashboardData.totalPickUps}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total pickups from even dials</b>
              </Typography>
              <Typography variant="h4">
                {dashboardData.totalPickUpsFromEvenDials}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total transcribed calls</b>
              </Typography>
              <Typography variant="h4">
                {dashboardData.totalTranscribedCalls}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total local pickups</b>
              </Typography>
              <Typography variant="h4">
                {dashboardData.totalPickUpsLocal}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total live transfers</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalLiveTransfers)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total live transfers from even dials</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(
                  dashboardData.totalLiveTransfersFromEvenDials
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total scheduled call backs</b>
              </Typography>
              <Typography variant="h4">
                {dashboardData.totalLeadCallBacks}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Bad numbers</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalFailedNumbers)}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Free minutes</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.freeMinutes)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Went to voicemail</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalVoiceMails)}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>No answer</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalNoAnswer)}
              </Typography>
            </CardContent>
          </Card> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>LT to pickup rate</b>
              </Typography>
              <Typography variant="h4">
                {dashboardData.liveTransferToPickupRate.toFixed(2)}%
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>Total overall dials</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalOverallDials)}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <b>LTs from local numbers</b>
              </Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalLocalLeadsLiveTransfers)}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Wallet balance</Typography>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Typography variant="h4">
                  ${dashboardData.walletBalance.toFixed(2)}
                </Typography>
                <div style={{ width: "35%" }} />
                <Button
                  variant="outlined"
                  color={"secondary"}
                  component={Link}
                  to="/billing"
                >
                  Manage
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Box mt={7}>
          <center>
            <Grid item xs={12}>
              <h2>Calling Queues</h2>

              {/* <br /> */}

              {/* <Button
                variant="contained"
                size="large"
                color={
                  dashboardData.callingQueuePaused ? "primary" : "secondary"
                }
                onClick={handlePauseResumeQueue}
              >
                {dashboardData.callingQueuePaused ? "START" : "PAUSE"}
                {dashboardData.callingQueuePaused ? (
                  <PlayArrowOutlined />
                ) : (
                  <Pause />
                )}
              </Button> */}

              {/* <br />
              <br />
              <br /> */}

              {queues.map((queue) => (
                <FormControlLabel
                  key={queue}
                  style={{
                    // border: "1px solid #000",
                    padding: "1em",
                    margin: "10px",
                    borderRadius: "20px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                  control={
                    <Switch
                      checked={!agentData.queue_status[queue].paused}
                      onChange={() => handleToggle(queue)}
                    />
                  }
                  label={queueMap[queue]}
                />
              ))}
            </Grid>
          </center>
        </Box>
      </Grid>
      {/**
      <Dialog open={resumingCallsDialogOpen}>
        <DialogContent>
          <DialogContentText>
            Resuming calls for all calling queues...
          </DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
       */}
      <Dialog open={resumingCallsForQueueDialogOpen}>
        <DialogContent>
          <DialogContentText>Resuming {queueName} calls...</DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={pausingCallsForQueueDialogOpen}>
        <DialogContent>
          <DialogContentText>Pausing {queueName} calls...</DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
      {
        //   <Dialog open={pausingCallsDialogOpen}>
        //   <DialogContent>
        //     <DialogContentText>
        //       Pausing calls for all calling queues...
        //     </DialogContentText>
        //     <div style={{ height: "1.5em" }} />
        //     <LinearProgress />
        //   </DialogContent>
        // </Dialog>
      }
    </div>
  );
}

export default Home;
