import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { db, auth } from "../config/firebase"; // Adjust the import according to your project structure
import {
  collection,
  query,
  getDocs,
  orderBy as firestoreOrderBy,
  limit,
  startAfter,
  where,
} from "firebase/firestore";

const LiveTransfers = () => {
  const [transfers, setTransfers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("time_stamp");

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentId = agentDoc.id;

      const transfersRef = collection(db, `agents/${agentId}/live_transfers`);
      const q = query(
        transfersRef,
        firestoreOrderBy(orderBy, order),
        limit(rowsPerPage)
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTransfers(data);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    };

    fetchData();
  }, [rowsPerPage, order, orderBy]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    if (newPage > page) {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentId = agentDoc.id;

      const transfersRef = collection(db, `agents/${agentId}/live_transfers`);
      const q = query(
        transfersRef,
        firestoreOrderBy(orderBy, order),
        startAfter(lastVisible),
        limit(rowsPerPage)
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTransfers(data);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "agent_phone_number"}
                  direction={orderBy === "agent_phone_number" ? order : "asc"}
                  onClick={() => handleRequestSort("agent_phone_number")}
                >
                  Agent Phone Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "call_sid"}
                  direction={orderBy === "call_sid" ? order : "asc"}
                  onClick={() => handleRequestSort("call_sid")}
                >
                  Call SID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "call_type"}
                  direction={orderBy === "call_type" ? order : "asc"}
                  onClick={() => handleRequestSort("call_type")}
                >
                  Call Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "lead_phone_number"}
                  direction={orderBy === "lead_phone_number" ? order : "asc"}
                  onClick={() => handleRequestSort("lead_phone_number")}
                >
                  Lead Phone Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "time_stamp"}
                  direction={orderBy === "time_stamp" ? order : "asc"}
                  onClick={() => handleRequestSort("time_stamp")}
                >
                  Time Stamp
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers.map((transfer) => (
              <TableRow key={transfer.id}>
                <TableCell>{transfer.agent_phone_number}</TableCell>
                <TableCell>{transfer.call_sid}</TableCell>
                <TableCell>{transfer.call_type}</TableCell>
                <TableCell>{transfer.lead_phone_number}</TableCell>
                <TableCell>
                  {new Date(
                    transfer.time_stamp.seconds * 1000
                  ).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={-1} // Unknown number of total rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default LiveTransfers;
