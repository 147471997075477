// src/UpdatePaymentMethod.js
import React from "react";
import StripeSetup from "./stripe-setup";
import CardForm from "./card-form";
import { auth, db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const UpdatePaymentMethod = () => {
  const handleCardSubmit = async (paymentMethodId) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentData = agentDoc.data();

      const response = await fetch(
        "https://updateagentpaymentmethod-7blmr5azyq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId,
            customerId: agentData.stripe_customer_id,
          }),
        }
      );

      const result = await response.json();
      if (result.success) {
        window.alert("Payment method updated successfully");
        window.location.reload();
      } else {
        window.alert(
          "Failed to update payment method, please try inputting a different card"
        );
      }
    } catch (error) {
      window.alert(
        "An error occurred while updating your payment method, please try again"
      );
      console.error("Error updating payment method:", error);
    }
  };

  return (
    <StripeSetup>
      <CardForm onCardSubmit={handleCardSubmit} />
    </StripeSetup>
  );
};

export default UpdatePaymentMethod;
