import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCJRfguEgHLOPFO8BxpYlmhBXlYGJRq96A",
  authDomain: "insurance-agent-usa.firebaseapp.com",
  projectId: "insurance-agent-usa",
  storageBucket: "insurance-agent-usa.appspot.com",
  messagingSenderId: "717635562383",
  appId: "1:717635562383:web:6e619f67554e681f16b9fa",
  measurementId: "G-WNG18PMJ5S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
