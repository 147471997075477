import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import "../styles/billing.css"; // Import the CSS file;
import UpdatePaymentMethod from "../components/update-payment-method";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { AGENT_AUTOPILOT_STRIPE_LIVE_KEY } from "../config/stripe";

const stripe = require("stripe")(
  // "sk_test_51LegOfH0DxDqRiYp0T7haQ8Jgb5ESkA56S17JZAqyuim8v0EUV0X6AD8xnlahVqpKiWdzjzQqopJ2G6vO8owHaWv00Hed0WsV0"
  AGENT_AUTOPILOT_STRIPE_LIVE_KEY
);

function Billing() {
  const [walletBalance, setWalletBalance] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(10);
  const [currentCard, setCurrentCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentData = agentDoc.data();
      setWalletBalance(agentData.wallet_balance.toFixed(2));
      setRechargeAmount(agentData.recharge_amount || 10);

      // Fetch current card details from Stripe
      const paymentMethods = await stripe.customers.listPaymentMethods(
        agentData.stripe_customer_id,
        {
          limit: 3,
        }
      );

      console.log(paymentMethods);

      if (paymentMethods.data.length > 0) {
        setCurrentCard(paymentMethods.data[0].card);
      }

      // if (stripe) {
      //   const customer = await stripe.customers.retrieve(
      //     agentData.stripe_customer_id
      //   );
      //   const paymentMethods = await stripe.paymentMethods.list({
      //     customer: agentData.stripe_customer_id,
      //     type: "card",
      //   });

      //   console.log(paymentMethods);
      // } else {
      //   console.log(stripe);
      // }
    };

    fetchData();
  }, []);

  const handleRechargeAmountChange = async (event) => {
    // if (event.target.value === "custom") return;

    const value = event.target.value;
    setRechargeAmount(value);

    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];
    const agentDocRef = doc(db, "agents", agentDoc.id);
    await updateDoc(agentDocRef, {
      recharge_amount: value,
    });
  };

  return (
    <>
      {/* <Box mb={4} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Billing
        </Typography>
      </Box> */}

      <div style={{ height: "75px" }} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={4}
      >
        <Box
          width="48%"
          p={3}
          ml={3}
          mr={3}
          style={{
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            borderRadius: "8px",
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
            color: "white",
          }}
          justifyItems="center"
          alignContent="center"
          textAlign="center"
        >
          <Typography variant="h5" gutterBottom>
            <b>Wallet Balance</b>
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            ${walletBalance}
          </Typography>
        </Box>

        <Box
          width="48%"
          p={3}
          ml={3}
          mr={3}
          style={{
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
            borderRadius: "8px",
            boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
            color: "white",
          }}
          textAlign="left"
        >
          <Typography variant="h5" gutterBottom textAlign="center">
            <b>Current Card on File</b>
          </Typography>
          {currentCard ? (
            <>
              <Typography variant="body1" gutterBottom>
                <strong>Card Number:</strong> **** **** **** {currentCard.last4}
              </Typography>
              <Typography variant="body1">
                <strong>Expiry Date:</strong> {currentCard.exp_month}/
                {currentCard.exp_year}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">No current card on file</Typography>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={4}
      >
        <Box
          width="48%"
          p={3}
          mr={3}
          ml={3}
          style={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            color: "black",
          }}
          textAlign="center"
        >
          <Typography variant="h6" gutterBottom>
            <b>Recharge Amount</b>
          </Typography>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Recharge Amount</InputLabel>
            <Select
              value={rechargeAmount}
              onChange={handleRechargeAmountChange}
              label="Recharge Amount"
            >
              <MenuItem value={5}>$5</MenuItem>
              <MenuItem value={10}>$10</MenuItem>
              <MenuItem value={25}>$25</MenuItem>
              <MenuItem value={50}>$50</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          width="48%"
          p={3}
          mr={3}
          ml={3}
          style={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            color: "black",
          }}
          textAlign="center"
        >
          <UpdatePaymentMethod />
        </Box>
      </Box>

      {/* <div className="form-container">
        <form onSubmit={handleUpdateCard}>
          <div>
            <label>Card Number:</label>
            <input
              type="text"
              name="cardNumber"
              value={cardDetails.cardNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Expiry Month:</label>
            <input
              type="text"
              name="expMonth"
              value={cardDetails.expMonth}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Expiry Year:</label>
            <input
              type="text"
              name="expYear"
              value={cardDetails.expYear}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>CVC:</label>
            <input
              type="text"
              name="cvc"
              value={cardDetails.cvc}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit">Update Card</button>
        </form>
      </div> */}
    </>
  );
}

export default Billing;
