import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./styles/side-panel.css";
import ManageFBLTPhoneCalls from "./pages/manage-fb-lt-calls";
import TestPhoneCalls from "./pages/test-phone-calls";
import logo from "../src/assets/images/logo.png";
import ManageCCLTPhoneCalls from "./pages/manage-cc-lt-calls";
import OnboardAgent from "./pages/onboard-agent";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Login from "./pages/login";
import PrivateRoute from "./pages/private-router";
import Billing from "./pages/billing";
import Home from "./pages/home";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./config/firebase";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import Webhook from "./pages/webhook";
import WebhookIcon from "@mui/icons-material/Webhook";
import LiveTransfers from "./pages/live-transfers";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CallIcon from "@mui/icons-material/Call";
import CallLogs from "./pages/call-logs";
import SettingsPage from "./pages/settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Settings } from "@mui/icons-material";
import ManageLeads from "./pages/manage-leads";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { set } from "firebase/database";

function App() {
  const [isPanelOpen, setPanelOpen] = useState(true);
  const [user, loading] = useAuthState(auth);
  const [_loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState("");
  const [agencyName, setAgencyName] = useState("");

  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        const user = auth.currentUser;
        // console.log("User:", user);
        if (!user) return;

        // Fetch the user's document
        const userDocRef = query(
          collection(db, "agents"),
          where("uid", "==", user.uid)
        );
        const userDoc = await getDocs(userDocRef);
        if (!userDoc.docs.length === 0) return;

        const userData = userDoc.docs[0].data();
        // console.log("User data:", userData);
        const agencyRef =
          userData.agency_ref ?? doc(db, "agencies", "idB9m08LPQaM0kDsFXLM");

        // Fetch the agency document
        const agencyDocRef = agencyRef;
        const agencyDoc = await getDoc(agencyDocRef);
        if (!agencyDoc.exists()) return;

        const agencyData = agencyDoc.data();
        const _logoUrl = agencyData.logo_url;

        // console.log("Logo URL:", _logoUrl);

        setLogoUrl(_logoUrl);
        setAgencyName(agencyData.name);
        document.title = `${agencyData.name} Voice AI`;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = agencyData.favicon_url;
      } catch (error) {
        console.error("Error fetching logo URL:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogoUrl();
  }, [user]);

  if (loading && _loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {isPanelOpen && user != null && (
          <div className="side-panel">
            <center>
              <img
                src={logoUrl}
                // src="https://lifeagentlaunch.com/wp-content/uploads/2024/06/lifeagentlaunchlogo-1.png"
                width={"150px"}
                style={{
                  borderRadius: "10%",
                  backgroundColor: "black",
                }}
              />
            </center>
            <h1
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "28px",
                fontWeight: "bold",
                fontFamily: "Arial",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              {agencyName} Voice AI
            </h1>
            <br />
            <br />
            <ul>
              <li>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <HomeIcon />
                  <div style={{ width: "10px" }} />
                  Home
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/manage-leads"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <ManageAccountsIcon />
                  <div style={{ width: "10px" }} />
                  Manage Leads
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/call-logs"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <CallIcon />
                  <div style={{ width: "10px" }} />
                  Call Logs
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/live-transfers"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <PhoneForwardedIcon />
                  <div style={{ width: "10px" }} />
                  Live Transfers
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/billing"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <AccountBalanceWalletIcon />
                  <div style={{ width: "10px" }} />
                  Billing
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/webhook"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <WebhookIcon />
                  <div style={{ width: "10px" }} />
                  Webhooks
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/settings"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <Settings />
                  <div style={{ width: "10px" }} />
                  Settings
                </Button>
              </li>
              <li>
                <Button
                  // component={Link}
                  color="primary"
                  variant="contained"
                  onClick={() => signOut(auth)}
                  fullWidth
                >
                  <LogoutIcon />
                  <div style={{ width: "10px" }} />
                  Sign out
                </Button>
              </li>
            </ul>
          </div>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/billing"
            element={
              <PrivateRoute>
                <div className="content">
                  <Billing />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/webhook"
            element={
              <PrivateRoute>
                <div className="content">
                  <Webhook />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <div className="content">
                  <Home />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-leads"
            element={
              <PrivateRoute>
                <div className="content">
                  <ManageLeads />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/call-logs"
            element={
              <PrivateRoute>
                <div className="content">
                  <CallLogs />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/live-transfers"
            element={
              <PrivateRoute>
                <div className="content">
                  <LiveTransfers />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <div className="content">
                  <SettingsPage />
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
