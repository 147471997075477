// src/StripeSetup.js
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { AGENT_AUTOPILOT_STRIPE_PUBLIC_KEY } from "../config/stripe";

const stripePromise = loadStripe(AGENT_AUTOPILOT_STRIPE_PUBLIC_KEY);

const StripeSetup = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeSetup;
